import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { ShareButton, ShareBlockStandard } from 'react-custom-share'
import { FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
import { injectIntl } from 'gatsby-plugin-intl'
import { P } from '../Typography'

const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 1.5rem 0;
  p {
    margin-bottom: 0;
  }
  button {
    color: white;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-right: 1rem;
    border: none;
    background: ${(props) => props.theme['$brand-primary300']};
    &:hover {
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      height: 20px;
      width: 20px;
    }
  }
`

const SocialShareBar = ({ intl, url, text, longtext }) => {
  const shareBlockProps = {
    url: `https://matton-residence.nl/${url}`,
    button: ShareButton,
    buttons: [
      { network: 'Facebook', icon: FaFacebook },
      { network: 'Linkedin', icon: FaLinkedin },
      { network: 'Twitter', icon: FaTwitter },
    ],
    text,
    longtext,
  }

  return (
    <>
      <hr />
      <Container>
        <P className="font-weight-medium mr-4">
          {intl.formatMessage({ id: 'SHARE_ARTICLE' })}
        </P>{' '}
        <ShareBlockStandard {...shareBlockProps} />
      </Container>
      <hr />
    </>
  )
}
SocialShareBar.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  longtext: PropTypes.string,
}

export default injectIntl(SocialShareBar)
