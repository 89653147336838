import React from 'react'
import { graphql } from 'gatsby'
import { IntlContextConsumer, injectIntl } from 'gatsby-plugin-intl'
import { GatsbyImage } from 'gatsby-plugin-image'
import { getGatsbyImageData } from 'gatsby-source-sanity'
import styled from 'styled-components'
import { Container, Col, Row } from '@bootstrap-styled/v4'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { H1, H3, P } from '../components/Typography'
import Section from '../components/UI/Section'
import Label from '../components/UI/Label'
import TextBlock from '../components/TextBlock'
import ContactMePersonal from '../components/ContactMePersonal'
import Footer from '../components/Footer'
import ObjectDetailImageHeader from '../components/ObjectDetail/ObjectDetailImageHeader'
import SocialShareBar from '../components/SocialShareBar'
import { slugify } from '../util/js/slugify'
import RecentBlogPostsBlock from '../components/RecentBlogPostsBlock'
import { months } from '../util/js/constants'
import breakpoints from '../util/style/breakpoints'
import OutboundLink from '../components/Link/OutboundLink'

export const blogPostQuery = graphql`
  query blogPostTemplateQuery($id: String!) {
    sanityBlogPost(id: { eq: $id }) {
      id
      _rawBlogTitle
      publishedAt
      author {
        name
        avatar {
          _key
          _rawAsset
        }
      }
      imgHeader {
        _key
        _rawAsset
      }
      blogCategory {
        category
      }
      _rawTextBlockBlog
      googleMapsUrl
      googleMapsImg {
        _key
        _rawAsset
        asset {
          url
        }
      }
    }
    allSanityBlogPost(limit: 3) {
      edges {
        node {
          id
          _rawBlogTitle
          publishedAt
          blogCategory {
            category
          }
          imgHeader {
            _key
            _rawAsset
            asset {
              url
            }
          }
        }
      }
    }
  }
`

const TemplateWrapper = styled.div`
  padding-top: 6.938rem;

  ${breakpoints.md`
    padding-top: 12.5rem;
  `}

  ${breakpoints.xl`
    padding-top: 17rem;
  `}
`

const SubHeader = styled.div`
  p {
    font-size: 0.813rem;
    color: ${(props) => props.theme['$text-muted']};
    margin: 0;
  }
`

const AvatarWrapper = styled.span`
  height: 40px;
  width: 40px;
  position: relative;
  display: inline-flex;
`

const blogPostPage = ({ intl, data }) => {
  const {
    id,
    _rawBlogTitle,
    publishedAt,
    author,
    imgHeader,
    blogCategory,
    _rawTextBlockBlog,
    googleMapsUrl,
    googleMapsImg,
  } = data.sanityBlogPost

  const sanityBlogPost = data.allSanityBlogPost && data.allSanityBlogPost.edges

  const sanityConfig = {
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
  }

  const avatarAsset =
    author.avatar && author.avatar._rawAsset
      ? getGatsbyImageData(author.avatar._rawAsset, { width: 40 }, sanityConfig)
      : undefined

  const currentDate = new Date(publishedAt)

  const formattedDate = `${currentDate.getDate()} ${intl.formatMessage({
    id: months[currentDate.getMonth()],
  })} ${currentDate.getFullYear()}`

  return (
    <Layout>
      <IntlContextConsumer>
        {({ language: currentLocale }) => (
          <>
            <SEO title={_rawBlogTitle[currentLocale]} />

            <TemplateWrapper>
              <Section>
                <Container>
                  <Row className="mb-3">
                    <Col className="d-flex justify-content-sm-center">
                      <Label text={blogCategory.category} />
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col>
                      <H1 className="text-sm-center">
                        {_rawBlogTitle[currentLocale]}
                      </H1>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <SubHeader className="text-center text-uppercase align-items-center justify-content-sm-center d-flex">
                        {avatarAsset && (
                          <AvatarWrapper className="mr-3">
                            <GatsbyImage
                              alt={`afbeelding-${author.name}`}
                              image={avatarAsset}
                              style={{
                                position: 'absolute',
                                left: 0,
                                top: 0,
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                              }}
                            />
                          </AvatarWrapper>
                        )}{' '}
                        <P>
                          {author.name} • {formattedDate}
                        </P>
                      </SubHeader>
                    </Col>
                  </Row>
                </Container>
              </Section>
              {imgHeader && imgHeader._rawAsset && (
                <Section>
                  <Container>
                    {/* Using object detail imageheader for blog posts, perhaps should rename the component */}
                    <ObjectDetailImageHeader imgHeader={imgHeader._rawAsset} />
                  </Container>
                </Section>
              )}
              <Section>
                <Container>
                  <Row className="mb-5">
                    <Col
                      md={{ size: 10, offset: 1 }}
                      xl={{ size: 8, offset: 2 }}
                    >
                      <TextBlock blocks={_rawTextBlockBlog[currentLocale]} />
                    </Col>
                  </Row>
                  {googleMapsImg && googleMapsImg._rawAsset && (
                    <>
                      <Row className="mb-5">
                        <Col
                          md={{ size: 10, offset: 1 }}
                          xl={{ size: 8, offset: 2 }}
                        >
                          <H3>{intl.formatMessage({ id: 'ON_THE_MAP' })}</H3>
                        </Col>
                      </Row>
                      <Row className="mb-5">
                        <Col
                          md={{ size: 10, offset: 1 }}
                          xl={{ size: 8, offset: 2 }}
                        >
                          <OutboundLink
                            to={googleMapsUrl}
                            target="_blank"
                            from={_rawBlogTitle[currentLocale]}
                            className="fade-link"
                          >
                            <ObjectDetailImageHeader
                              imgHeader={googleMapsImg._rawAsset}
                            />
                          </OutboundLink>
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row>
                    <Col
                      md={{ size: 10, offset: 1 }}
                      xl={{ size: 8, offset: 2 }}
                    >
                      <SocialShareBar
                        url={`/${slugify(_rawBlogTitle.nl)}-${id.substring(
                          0,
                          6
                        )}`}
                        text={_rawBlogTitle[currentLocale]}
                      />
                    </Col>
                  </Row>
                </Container>
              </Section>
              <Section />
            </TemplateWrapper>
          </>
        )}
      </IntlContextConsumer>
      {sanityBlogPost && (
        <Section>
          <Container>
            <RecentBlogPostsBlock data={sanityBlogPost} />
          </Container>
        </Section>
      )}
      <Section>
        <Container>
          <ContactMePersonal
            header={intl.formatMessage({ id: 'contact.title' })}
            subTitle={intl.formatMessage({ id: 'contact.SUB_TITLE_AMBER' })}
            contactUs={intl.formatMessage({
              id: 'CONTACT_US',
            })}
          />
        </Container>
      </Section>
      <Footer />
    </Layout>
  )
}

export default injectIntl(blogPostPage)
