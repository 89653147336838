import React from 'react'
import { injectIntl, IntlContextConsumer } from 'gatsby-plugin-intl'
import { Col, Row } from '@bootstrap-styled/v4'
import { slugify } from '../../util/js/slugify'
import PreviewCard from '../PreviewCard'
import { H2 } from '../Typography'
import PreviewCardAsLink from '../PreviewCard/PreviewCardAsLink'
import { months } from '../../util/js/constants'

const RecentBlogPostsBlock = ({ intl, data }) => (
  <IntlContextConsumer>
    {({ language: currentLocale }) => (
      <>
        <Row className="mb-5">
          <Col xs={12}>
            <H2>{intl.formatMessage({ id: 'RECENT_BLOG_POSTS' })}</H2>
          </Col>
        </Row>
        <Row>
          <>
            {data.map(({ node }) => {
              const url =
                node._rawBlogTitle.nl &&
                `/${slugify(node._rawBlogTitle.nl)}-${node.id.substring(0, 6)}`

              const title = node._rawBlogTitle[currentLocale]
              const category = node.blogCategory && node.blogCategory.category
              const image = node.imgHeader && node.imgHeader._rawAsset
              const currentDate = new Date(node.publishedAt)
              const formattedDate = `${currentDate.getDate()} ${intl.formatMessage(
                {
                  id: months[currentDate.getMonth()],
                }
              )} ${currentDate.getFullYear()}`

              return (
                <Col md={4} key={node.id}>
                  <PreviewCardAsLink link={url}>
                    <PreviewCard
                      header={title}
                      subHeader={formattedDate}
                      previewImage={image}
                      label={category}
                    />
                  </PreviewCardAsLink>
                </Col>
              )
            })}
          </>
        </Row>
      </>
    )}
  </IntlContextConsumer>
)

export default injectIntl(RecentBlogPostsBlock)
